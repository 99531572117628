<template>
	<popper v-if="data.value" class="popper-light m-0 border-0" arrow>
		<div class="pointer" @click="isOpen = true">
			<kaveh-icon-bootstrap name="bi-code-slash" color="inherit" class="ms-1" size="13px" />
		</div>
		<template #content>
			<div dir="ltr" v-html="getHtmlValue()"></div>
		</template>
	</popper>
</template>
<script>
export default {
		props:
		{
			data: Object
		},

		data()
		{
			return {
				isOpen: false
			}
		},

		methods: {
			getHtmlValue()
			{
				return this.data.value.replace(new RegExp('\n', 'g'), '<br/>').replace(new RegExp('\t', 'g'), '&nbsp;&nbsp;&nbsp;&nbsp;')
			}
		}
}
</script>

