//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class BlogContent extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'blogContent';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'cms.blogContent';
	props = {
		blogContentId: {
			primarykey: true,
			name: 'blogContentId',
			resourceKey: 'cms.blogContentBlogContentId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		slug: {
			name: 'slug',
			resourceKey: 'cms.blogContentSlug',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: true,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'cms.blogContentTitle',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: true,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'cms.blogContentCaption',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXTAREA,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 150,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		textContent: {
			name: 'textContent',
			resourceKey: 'cms.blogContentTextContent',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT_EDITOR,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		keywords: {
			name: 'keywords',
			resourceKey: 'cms.blogContentKeywords',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		isActive: {
			name: 'isActive',
			resourceKey: 'cms.blogContentIsActive',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		blogCategoryId: {
			foreignKey: true,
			name: 'blogCategoryId',
			resourceKey: 'cms.blogContentBlogCategoryId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/cms/blog-category/list',
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'blogCategoryId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		identifier: {
			name: 'identifier',
			resourceKey: 'cms.blogContentIdentifier',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
	};
}