<template>
	<input v-bind:id="prop.id"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelChange"
		   inputmode="numeric"
		   dir="ltr"
		   v-model="value"
		   type="text"
		   class="form-control"
		   autocomplete="off" />
	<small v-if="getWords() != ''" class="text-muted d-block mt-1 mb-1">{{getWords()}}</small>
</template>
<script>

	import { numberToWords, addCommas, removeCommas } from "@persian-tools/persian-tools";
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null,
				changed: false,
			}
		},

		beforeMount()
		{
			if (this.prop.value != undefined) { this.value = String(this.prop.value) }
		},

		methods: {

			getNumberValue()
			{
				return Number(this.getStringValue());
			},

			getStringValue()
			{
				return removeCommas(String(this.value))
			},

			getWords()
			{
				if (!this.prop.name.endsWith('Id') && this.getNumberValue() > 999)
				{
					var value =
						this.getNumberValue();

					return value != "" ? numberToWords(value) : "";
				}

				return "";
			},

			getCommaValue()
			{
				if (!this.prop.name.endsWith('Id'))
				{
					return addCommas(this.getNumberValue());
				}

				return this.getStringValue();
			},

			onHandelChange()
			{
				const numberValue = this.getNumberValue()
				const stringValue = this.getStringValue()


				if (stringValue == "" || !Number.isInteger(numberValue))
				{
					this.value = "";
					if (this.changed)
					{
						this.$emit('change', "");
					}
				}
				else
				{
					this.value = this.getCommaValue()
					this.$emit('change', numberValue);
					this.changed = true;
				}
			},

			onHandelKeyUp(e)
			{

				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('submit')
				}
				else
				{

					this.onHandelChange()
				}
			}
		}
	}
</script>
