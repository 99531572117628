//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class StaticPageService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[StaticPage.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/cms/static-page/create', params || { });
	};

	//GeneratorFlag:[StaticPage.Delete]
	async delete(staticPageId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:staticPageId
		}
	
		return await _server.delete('/cms/static-page/delete', {'params':params})
	};

	//GeneratorFlag:[StaticPage.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/cms/static-page/detail', {'params':params});
	};

	//GeneratorFlag:[StaticPage.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/cms/static-page/list', {'params':params});
	};

	//GeneratorFlag:[StaticPage.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/cms/static-page/update', params || { });
	};

//GeneratorFlag:[NewAction]
}