//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class AccessTokenService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[AccessToken.Destroy]
	async destroy(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/access-token/destroy', params || { });
	};

	//GeneratorFlag:[AccessToken.Generate]
	async generate(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/access-token/generate', params || { });
	};

	//GeneratorFlag:[AccessToken.GenerateGoogle]
	async generateGoogle(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/access-token/generate-google', params || { });
	};

//GeneratorFlag:[NewAction]
}