import ext from './en-ext.js'
export default {
	"name": "hub",
	"ext": ext,
	"layout": {
		"geographical": "Geographical Information",
		"basis": "Basic Information"
	},
	"accessAgent": "Access Management",
	"accessAgentAccessKeys": "Keys",
	"accessAgentLayout": "Access Management",
	"accessAgentSave": "Access Management",
	"accessAgentSaveIsSuccess": "Result",
	"accessAgentSavePersonId": "User",
	"accessAgentSavePersonRoleId": "User Role",
	"accessToken": "Get Token",
	"accessTokenGenerate": "Get Token",
	"accessTokenGenerateExpirationDate": "Expiration Date",
	"accessTokenGeneratePassword": "Password",
	"accessTokenGenerateToken": "Token",
	"accessTokenGenerateTokenType": "Token Type",
	"accessTokenGenerateUserName": "Username",
	"accessTokenLayout": "Get Token",
	"branch": "Organizational Branch",
	"branchAddress": "Office Address",
	"branchBranchId": "ID",
	"branchBranchName": "Branch Name",
	"branchBranchTrack": "Registration Tracking Information",
	"branchBranchType": "Parent",
	"branchBranchTypeId": "Parent",
	"branchCity": "Office City",
	"branchCityId": "Office City",
	"branchCreate": "Register New Organizational Branch",
	"branchCreateAddress": "Address",
	"branchCreateBranchId": "ID",
	"branchCreateBranchName": "Organizational Branch Title",
	"branchCreateBranchTypeId": "Parent",
	"branchCreateCityId": "City",
	"branchCreateParentId": "Parent",
	"branchCreatePhone": "Phone",
	"branchDelete": "Delete Organizational Branch",
	"branchDetail": "Organizational Branch Information Details",
	"branchLayout": "Organizational Branches",
	"branchList": "List of Organizational Branches",
	"branchMove": "Move Organizational Branches",
	"branchParentId": "Parent",
	"branchParentName": "Parent Title",
	"branchPhone": "Office Phone",
	"branchPosition": "Position",
	"branchTenantId": "Center",
	"branchTree": "Tree",
	"branchTypeBranchTypeId": "ID",
	"branchTypeCaption": "Description",
	"branchTypeTitle": "Title",
	"branchUpdate": "Edit Organizational Branch",
	"branchUpdateAddress": "Address",
	"branchUpdateBranchId": "ID",
	"branchUpdateBranchName": "Organizational Branch Title",
	"branchUpdateBranchTypeId": "Parent",
	"branchUpdateCityId": "City",
	"branchUpdateParentId": "Parent",
	"branchUpdatePhone": "Phone",
	"city": "City",
	"cityAttachment": "File Attachment",
	"cityCaption": "Description",
	"cityCityId": "ID",
	"cityCityTrack": "Registration Tracking Information",
	"cityCreate": "Register New City",
	"cityCreateImport": "Register Cities via Excel",
	"cityDelete": "Delete City",
	"cityDetail": "City Information Details",
	"cityIdentifier": "Unique Key",
	"cityLayout": "Cities",
	"cityList": "List of Cities",
	"cityNativeName": "City Name",
	"cityProvince": "Province",
	"cityProvinceId": "Province",
	"cityUpdate": "Edit City",
	"country": "Country",
	"countryAttachment": "File Attachment",
	"countryCapitalName": "Capital Name",
	"countryCaption": "Description",
	"countryCode": "Country Code",
	"countryCountryId": "ID",
	"countryCountryTrack": "Registration Tracking Information",
	"countryCreate": "Register New Country",
	"countryCreateImport": "Register Countries via Excel",
	"countryDelete": "Delete Country",
	"countryDetail": "Country Information Details",
	"countryIdentifier": "Unique Key",
	"countryLayout": "Countries",
	"countryList": "List of Countries",
	"countryNativeName": "Country Name",
	"countryUpdate": "Edit Country",
	"keyValue": "Key",
	"keyValueAttachment": "File Attachment",
	"keyValueCreate": "Register New Key",
	"keyValueCreateImport": "Register Keys via Excel",
	"keyValueDelete": "Delete Key",
	"keyValueDetail": "Key Information Details",
	"keyValueIdentifier": "Unique Key",
	"keyValueIsPublic": "Is Key Public?",
	"keyValueKeyValueId": "ID",
	"keyValueKeyValueTrack": "Registration Tracking Information",
	"keyValueLayout": "Keys",
	"keyValueList": "List of Keys",
	"keyValueTextKey": "Key",
	"keyValueTextValue": "Value",
	"keyValueTitle": "Title",
	"keyValueUpdate": "Edit Key",
	"person": "Person",
	"personAccess": "Access Information",
	"personAccessAccessIps": "Authorized IPs",
	"personAccessDetail": "Access Information Details",
	"personAccessForceChangePassword": "Change Password on First Login?",
	"personAccessIsActive": "Is Access Active?",
	"personAccessPassword": "Password",
	"personAccessPersonId": "Person ID",
	"personAccessTwoStepLogin": "Two-Step Login?",
	"personAccessUpdate": "Edit Access Information",
	"personAccessUseHardwareToken": "Hardware Token?",
	"personAccessUserName": "Username",
	"personAttachment": "File Attachment",
	"personChangePassword": "Change Password",
	"personChangePasswordNewPassword": "New Password",
	"personChangePasswordOldPassword": "Current Password",
	"personCity": "City",
	"personCityId": "City",
	"personCreate": "Register New Person",
	"personCreateImport": "Register Persons via Excel",
	"personDelete": "Delete Person",
	"personDetail": "Person Information Details",
	"personEntityCaption": "Description",
	"personEntityPersonEntityId": "ID",
	"personEntityTitle": "Title",
	"personGenderCaption": "Description",
	"personGenderPersonGenderId": "ID",
	"personGenderTitle": "Title",
	"personGradeCaption": "Description",
	"personGradePersonGradeId": "ID",
	"personGradeTitle": "Title",
	"personGroupCaption": "Description",
	"personGroupPersonGroupId": "ID",
	"personGroupTitle": "Title",
	"personIdentifier": "Unique Key",
	"personLayout": "Persons",
	"personLegal": "Legal Information",
	"personLegalAddress": "Address",
	"personLegalDetail": "Legal Information Details",
	"personLegalEconomicNumber": "Economic ID",
	"personLegalEmail": "Email",
	"personLegalFax": "Fax",
	"personLegalLegalName": "Legal Name",
	"personLegalNationalCode": "National ID",
	"personLegalPhoneNumber": "Phone Number",
	"personLegalPostalCode": "Postal Code",
	"personLegalRegisterNumber": "Registration ID",
	"personLegalUpdate": "Edit Legal Information",
	"personList": "List of Persons",
	"personMaritalCaption": "Description",
	"personMaritalPersonMaritalId": "ID",
	"personMaritalTitle": "Title",
	"personMilitary": "Military Status",
	"personMilitaryAttachment": "File Attachment",
	"personMilitaryCaption": "Description",
	"personMilitaryCreate": "Register New Military Status",
	"personMilitaryCreateImport": "Register Military Statuses via Excel",
	"personMilitaryDelete": "Delete Military Status",
	"personMilitaryDetail": "Military Status Information Details",
	"personMilitaryIdentifier": "Unique Key",
	"personMilitaryLayout": "Military Statuses",
	"personMilitaryList": "List of Military Statuses",
	"personMilitaryPersonMilitaryId": "ID",
	"personMilitaryPersonMilitaryTrack": "Registration Tracking Information",
	"personMilitaryPersonReal": "Person Real",
	"personMilitaryTitle": "Title",
	"personMilitaryUpdate": "Edit Military Status",
	"personNationality": "Nationality",
	"personNationalityAttachment": "File Attachment",
	"personNationalityCaption": "Description",
	"personNationalityCreate": "Register New Nationality",
	"personNationalityCreateImport": "Register Nationalities via Excel",
	"personNationalityDelete": "Delete Nationality",
	"personNationalityDetail": "Nationality Information Details",
	"personNationalityIdentifier": "Unique Key",
	"personNationalityLayout": "Nationalities",
	"personNationalityList": "List of Nationalities",
	"personNationalityPersonNationalityId": "ID",
	"personNationalityPersonNationalityTrack": "Registration Tracking Information",
	"personNationalityPersonReal": "Person Real",
	"personNationalityTitle": "Title",
	"personNationalityUpdate": "Edit Nationality",
	"personPersonAccess": "Access Information",
	"personPersonEntity": "Entity Type",
	"personPersonEntityId": "Entity Type",
	"personPersonGroup": "Group",
	"personPersonGroupId": "Group",
	"personPersonId": "ID",
	"personPersonLegal": "Legal Information",
	"personPersonName": "Name",
	"personPersonReal": "Real Information",
	"personPersonRole": "User Role",
	"personPersonRoleId": "User Role",
	"personPersonTrack": "Registration Tracking Information",
	"personPersonVehicle": "Vehicle Information",
	"personReal": "Real Information",
	"personRealBirthDate": "Date of Birth",
	"personRealDetail": "Real Information Details",
	"personRealEmail": "Email",
	"personRealFatherName": "Father's Name",
	"personRealFirstName": "First Name",
	"personRealFullName": "Full Name",
	"personRealHomeAddress": "Home Address",
	"personRealIdentification": "Identification Number",
	"personRealLastName": "Last Name",
	"personRealMobile": "Mobile",
	"personRealMotherName": "Mother's Name",
	"personRealNationalCode": "National Code",
	"personRealPersonGender": "Gender",
	"personRealPersonGenderId": "Gender",
	"personRealPersonGrade": "Education Grade",
	"personRealPersonGradeId": "Education Grade",
	"personRealPersonId": "Person ID",
	"personRealPersonMarital": "Marital Status",
	"personRealPersonMaritalId": "Marital Status",
	"personRealPersonMilitary": "Military Status",
	"personRealPersonMilitaryId": "Military Status",
	"personRealPersonNationality": "Nationality",
	"personRealPersonNationalityId": "Nationality",
	"personRealPhoneNumber": "Phone Number",
	"personRealPlaceOfBirth": "Place of Birth",
	"personRealPostalCode": "Postal Code",
	"personRealUpdate": "Edit Real Information",
	"personRealWorkAddress": "Work Address",
	"personRole": "User Role",
	"personRoleAttachment": "File Attachment",
	"personRoleCaption": "Description",
	"personRoleCreate": "Register New User Role",
	"personRoleCreateImport": "Register User Roles via Excel",
	"personRoleDelete": "Delete User Role",
	"personRoleDetail": "User Role Information Details",
	"personRoleIdentifier": "Unique Key",
	"personRoleLayout": "User Roles",
	"personRoleList": "List of User Roles",
	"personRolePersonRoleId": "ID",
	"personRolePersonRoleTrack": "Registration Tracking Information",
	"personRoleTenant": "ID",
	"personRoleTenantId": "ID",
	"personRoleTitle": "Title",
	"personRoleUpdate": "Edit User Role",
	"personTenant": "Center",
	"personTenantId": "Center",
	"personUpdate": "Edit Person",
	"personVehicle": "Vehicle Information",
	"personVehicleCertificateNumber": "License Number",
	"personVehicleDetail": "Vehicle Information Details",
	"personVehiclePersonId": "ID",
	"personVehicleUpdate": "Edit Vehicle Information",
	"personVehicleVehicleColor": "Color",
	"personVehicleVehicleName": "Vehicle Name",
	"personVehicleVehiclePlaque": "Plaque",
	"position": "Position",
	"positionBranch": "Organizational Position",
	"positionBranchId": "Organizational Position",
	"positionCreate": "Register New Position",
	"positionCreateImport": "Register Positions via Excel",
	"positionCreateTime": "Registration Time",
	"positionCreatorName": "Registration User",
	"positionDelete": "Delete Position",
	"positionDetail": "Position Information Details",
	"positionEndDate": "End Date of Position",
	"positionHasAccessExternalSign": "Ability to Sign External Letters",
	"positionHasAccessFirstSign": "Ability to First Sign",
	"positionHasAccessInternalSign": "Ability to Sign Internal Letters",
	"positionHasAccessSecondSign": "Ability to Second Sign",
	"positionIsDefaultPosition": "Default Position",
	"positionLayout": "Position",
	"positionList": "List of Positions",
	"positionLocalCreateDate": "Registration Date",
	"positionPerson": "Personnel",
	"positionPersonId": "Personnel",
	"positionPersonName": "Name",
	"positionPositionId": "ID",
	"positionPositionTrack": "Registration Tracking Information",
	"positionStartDate": "Start Date of Position",
	"positionUpdate": "Edit Position",
	"province": "Province",
	"provinceAttachment": "File Attachment",
	"provinceCaption": "Description",
	"provinceCountry": "Country",
	"provinceCountryId": "Country",
	"provinceCreate": "Register New Province",
	"provinceCreateImport": "Register Provinces via Excel",
	"provinceDelete": "Delete Province",
	"provinceDetail": "Province Information Details",
	"provinceIdentifier": "Unique Key",
	"provinceLayout": "Provinces",
	"provinceList": "List of Provinces",
	"provinceNativeName": "Province Name",
	"provinceProvinceId": "ID",
	"provinceProvinceTrack": "Registration Tracking Information",
	"provinceUpdate": "Edit Province",
	"region": "Region",
	"regionAttachment": "File Attachment",
	"regionCaption": "Description",
	"regionCity": "City",
	"regionCityId": "City",
	"regionCreate": "Register New Region",
	"regionCreateImport": "Register Regions via Excel",
	"regionDelete": "Delete Region",
	"regionDetail": "Region Information Details",
	"regionIdentifier": "Unique Key",
	"regionLayout": "Regions",
	"regionList": "List of Regions",
	"regionNativeName": "Region Name",
	"regionRegionId": "ID",
	"regionRegionTrack": "Registration Tracking Information",
	"regionUpdate": "Edit Region",
	"tenant": "Center",
	"tenantAddress": "Address",
	"tenantCaption": "Description",
	"tenantCity": "City",
	"tenantCityId": "City",
	"tenantCreate": "Register New Center",
	"tenantCreateImport": "Register Centers via Excel",
	"tenantDelete": "Delete Center",
	"tenantDetail": "Center Information Details",
	"tenantInstagramAddress": "Instagram Address",
	"tenantLayout": "Centers",
	"tenantList": "List of Centers",
	"tenantOtherSocialMedia": "Other Social Media Address",
	"tenantParentId": "Subsidiary",
	"tenantSiteAddress": "Website Address",
	"tenantSubDomain": "Subdomain",
	"tenantTenantId": "ID",
	"tenantTenantTrack": "Registration Tracking Information",
	"tenantTenantType": "Center Type",
	"tenantTenantTypeId": "Center Type",
	"tenantTitle": "Name",
	"tenantTypeCultureId": "Language",
	"tenantTypeIsOrigin": "Root",
	"tenantTypeTenantTypeKey": "Key",
	"tenantTypeTitle": "Title",
	"tenantUpdate": "Edit Center"
}