const de = {
	service: {
		hub: "Infrastruktur",
		shop: "Online-Shop",
		cms: "Content-Management",
		guard: "Wächter",
		cpay: "Kreditzahlung",
		aut: "Automatisierung",
		post: "Beiträge",
		nem: "Netzwerkausrüstung",
		messenger: "Messenger",
		asset: "Dateien",
		cms: "Content management system",
		store: "Speichern",
		finance: "Finanziell"
	},
	global: {
		applicationName: "Kaveh Software",
		signIn: "Anmelden",
		logOut: "Abmelden",
		save: "Daten speichern",
		ApplyFilters: "Filter anwenden",
		excelKey: "Excel-Schlüssel",
		operations: "Operationen",
		mathOperators: "Mathematische Operatoren",
		RelOperators: "Relationale Operatoren",
		logicOperators: "Logische Operatoren",
		copyright: "Entworfen und produziert von Kaveh Software Group",
		filters: "Filter",
		tools: "Werkzeuge",
		yes: "Ja",
		no: "Nein",
		row: "Zeile",
		name: "Name"
	},
	languages: {
		fa: "persian",
		en: "english",
		de: "germany",
	},
	system: {
		pleaseWait: "Bitte warten...",
		pleaseWaite: "Bitte warten...",
		downloading: "Das System lädt Daten herunter, bitte warten Sie.",
		uploading: "Das System lädt Daten hoch, bitte warten Sie.",
		progressing: "Das System führt eine Operation durch, bitte warten Sie.",
		notfound: "Keine Daten zum Anzeigen gefunden!",
		dataError: "Beim Abrufen der Daten ist ein Fehler aufgetreten! Bitte kontaktieren Sie den System-Support.",
		wellcome: "Willkommen im integrierten Kaveh Organizational System. </br> Dieses System wurde mit modernsten Webtechnologien entwickelt und kann auf verschiedenen Betriebssystemen installiert werden."
	},
	layout: {
		default: "Standardvorlage",
		minimal: "Minimale Vorlage"
	},
	router: {
		back: "Zurück"
	},
	status: {
		errorLen: "Fehleranzahl",
		successLen: "Erfolgsanzahl",
		errorShort: "Nicht erfolgreiche Operation",
		successShort: "Erfolgreiche Operation",
		error: "Es ist ein Fehler aufgetreten.",
		ok: "Operation erfolgreich abgeschlossen.",
		notFound: "Wert nicht gefunden -- {message}",
		unknown: "Interner Serverfehler! Bitte kontaktieren Sie den System-Support. {message}",
		unavailable: "Dienst nicht verfügbar",
		internal: "Interner Fehler {message}",
		unauthorized: "Sie haben keinen Zugriff auf diesen Abschnitt oder Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
		unauthenticated: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
		invalidArgument: "Die eingegebenen Daten sind ungültig. {message}",
		alreadyExists: "Die eingegebenen Daten existieren bereits. {message}",
		permissionDenied: "Ungültige Zugriffsberechtigung. {message}"
	},
	message: {
		attachmentImageAllert: "Diese Seite dient dem Hochladen öffentlicher Bilder. Laden Sie hier keine vertraulichen Dateien hoch.",
		deleteRelationError: "Aufgrund von Beziehungen zu anderen Abschnitten des Systems ist eine Löschung nicht möglich",
		invalidPrimaryKey: "Ungültiger Primärschlüssel",
		networkError: "Fehler beim Verbinden mit dem Server!",
		serverError: "Interner Serverfehler! Bitte kontaktieren Sie den System-Support.",
		noResponse: "Server nicht verfügbar! Es wurde keine Antwort auf die Anfrage erhalten.",
		badRequest: "Ungültige Anfrage!",
		unavailableService: "Dienst nicht verfügbar",
		emptyResult: "Keine Daten zum Anzeigen gefunden",
		permissionDenied: "Sie haben keinen Zugriff auf diesen Abschnitt. Bitte koordinieren Sie mit dem Systemadministrator, um Ihre Zugriffsstufen zu vervollständigen.",
		unauthorized: "Sie haben keinen Zugriff auf diesen Abschnitt oder Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
		loginError: "Falscher Benutzername oder falsches Passwort",
		deleteConfirm: "Möchten Sie wirklich löschen?",
		confirm: "Sind Sie sicher?",
		confirmSyncDistributeDatabase: "Sie synchronisieren gemeinsame Daten zwischen verteilten Datenbanken! Die Synchronisierung erfolgt immer automatisch. Wenn in dem verteilten System ein Fehler auftritt, setzen Sie diesen Vorgang fort. Der Vorgang kann einige Zeit dauern. Sind Sie sicher, dass Sie den Vorgang fortsetzen möchten?"
	},
	action: {
		create: "Hinzufügen",
		save: "Daten speichern",
		responce: "Antwort",
		enter: "Eingeben",
		exit: "Beenden",
		sendResponce: "Antwort senden",
		createNewNode: "Neuen Zweig hinzufügen",
		update: "Bearbeiten",
		fileAttachment: "Dateianhang",
		imageAttachment: "Bildanhang",
		download: "Herunterladen",
		uploadSelectedFiles: "Ausgewählte Dateien hochladen",
		filter: "Filter",
		import: "Daten importieren",
		createImport: "Daten importieren",
		export: "Daten exportieren",
		exportExcel: "Als Excel exportieren",
		importFromExcel: "Aus Excel importieren",
		ApplyFilters: "Filter anwenden",
		history: "Verlauf anzeigen",
		importData: "Daten importieren",
		delete: "Löschen",
		deleteData: "Daten löschen",
		back: "Zurück",
		close: "Schließen",
		search: "Suche",
		searchPoint: "Suchen...",
		more: "Mehr",
		applyFilter: "Filter anwenden",
		toggleFilter: "Filter umschalten",
		showList: "Liste anzeigen",
		submitChange: "Änderungen übernehmen",
		pleaseSelect: "Bitte auswählen",
		downloadReport: "Bericht herunterladen",
		neverMind: "Nicht wichtig",
		prev: "Vorherige",
		next: "Nächste"
	},
	page: {
		kaveh: "Kaveh Software",
		home: "Startseite",
		profile: "Profil",
		changePass: "Passwort ändern",
		notFound: "Seite nicht gefunden",
		cpanel: "Kaveh Software",
		baseInformation: "Grundlegende Systeminformationen",
		auth: "Systemanmeldung",
		dashboard: "Dashboard",
		baseInfo: "Verwaltung grundlegender Informationen",
		baseSys: "Systeminformationen",
		service: "Dienste",
		cycles: "Zyklen",
		accessDenied: "Zugriff verweigert",
		print: "Drucken"
	},
	error: {
		checkForm: "Bitte überprüfen Sie zuerst die Formularfehler und beheben Sie sie.",
		required: "Das Feld {label} ist erforderlich",
		invalid: "Das Feld {label} ist ungültig",
		digit: "Das Feld {label} muss eine nichtnull Zahl sein",
		number: "Das Feld {label} muss eine Zahl sein",
		stringLength: "Das Feld {label} überschreitet die maximal zulässige Zeichenanzahl",
		noUnicodeString: "Nur englische Buchstaben und Zahlen sind erlaubt",
		byteOutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 255",
		int16OutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 32.767",
		uint16OutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 65.535",
		int32OutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 2.147.483.647",
		uint32OutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 4.294.967.295",
		int64OutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 9.223.372.036.854.775.807",
		uint64OutOfRange: "Der maximal zulässige Wert für dieses Feld beträgt 18.446.744.073.709.551.615",
		password: "Die Mindestlänge des Passworts muss 8 Zeichen betragen"
	},
	title: {
		attachmentsList: "Anhänge Liste"
	},
	data: {
		uniquekey: "Eindeutiger Schlüssel",
		track: {
			self: "Registrierungsinformationen",
			creatorId: "Ersteller-ID",
			creatorIp: "Ersteller-IP",
			creatorName: "Erstellername",
			createDate: "Erstellungsdatum (Gregorianisch)",
			createUtcDate: "Erstellungsdatum (UTC)",
			localCreateDate: "Erstellungsdatum (Persisch)",
			createTime: "Erstellungszeit",
			createUtcTime: "Erstellungszeit (UTC)",
			modifierId: "Änderer ID",
			modifierIp: "Änderer IP",
			modifierName: "Änderer Name",
			modifyDate: "Änderungsdatum (Gregorianisch)",
			modifyUtcDate: "Änderungsdatum (UTC)",
			localModifyDate: "Änderungsdatum (Persisch)",
			modifyTime: "Änderungszeit",
			modifyUtcTime: "Änderungszeit (UTC)",
			summary: "Von {creatorName} am {localCreateDate}"
		},
		userAgent: {
			self: "Benutzerinformationen",
			userName: "Benutzername",
			password: "Passwort"
		},
		history: {
			self: "Verlauf",
			entityValue: "Änderungen",
			historyName: "Registrierungstyp",
			fullName: "Vollständiger Benutzername",
			id: "Benutzer-ID",
			ip: "Benutzer-IP"
		},
		historyTime: {
			self: "Zeitinformationen",
			createDate: "Datum (Gregorianisch)",
			createPersianDate: "Datum (Persisch)",
			createTime: "Uhrzeit"
		},
		baseEntityForm: "Formularinformationen"
	},
	com: {
		excel: {
			chooseFile: "Datei auswählen",
			backToSelectFile: "Zurück",
			review: "Überprüfen",
			reviewLength: "Anzahl der durchgeführten Überprüfungen",
			NumberOfReviewsPerformed: "Anzahl der durchgeführten Überprüfungen",
			reviewMessage: "Wenn nach der Überprüfung keine Fehler gefunden wurden, wird das System mit der Dateneingabe fortfahren.",
			fileIsEmpty: "Ausgewählte Datei ist leer!",
			downloadSampleFile: "Beispieldatei herunterladen",
			fileProcessing: "Das System verarbeitet die Datei, bitte warten Sie.",
			fileHasError: "Datei enthält ungültige Daten.",
			warning: "Bitte achten Sie bei der Dateneingabe über die Excel-Datei auf folgende Punkte:",
			sampleFileName: "Beispieldatei {sheetName} - {id}"
		},
		table: {
			filterResult: "Gefiltert auf {totalfilters} Zeilen.",
			pageInfo: "Seite {currentPage} von {totalPages}",
			action: {
				exportCurentPageAsExcel: "Aktuelle Seite als Excel herunterladen",
				createReport: "Bericht erstellen"
			},
			headerSearch: "Suche in {label}",
			searchPlaceholder: "Geben Sie Ihren gewünschten Wert ein und drücken Sie die Eingabetaste",
			report: {
				progressing: "Das System erstellt und lädt den Bericht herunter, bitte warten Sie...",
				done: "Operation erfolgreich abgeschlossen. Die Datei wird automatisch heruntergeladen."
			}
		},
		task: {
			repeat: "Wiederholen",
			skip: "Überspringen",
			replace: "Ersetzen",
			cancel: "Operation abbrechen",
			doTasks: "Aufgaben durchführen",
			cancelTasks: "Aufgaben abbrechen",
			errorContent: "Fehlerinhalt",
			taskRowNumberError: "Datensatznummer {index} ist auf einen Fehler gestoßen.",
			tasksFailedLenght: "Anzahl fehlgeschlagener Datensätze",
			tasksSucceedLenght: "Anzahl erfolgreicher Datensätze",
			applyToAllCases: "Diese Auswahl auf alle zukünftigen Fälle mit ähnlichen Fehlern anwenden",
			progressing: "Das System führt die Operation durch, bitte warten Sie.",
			tasksCompletedWithError: "Operation mit Fehlern abgeschlossen.",
			tasksCompletedSuccess: "Operation erfolgreich abgeschlossen.",
			excelRowIndex: "Excel-Dateizeilennummer",
			errorDetail: "Excel-Dateizeilennummer",
			warning: "Die folgenden Daten sind für {label} ausgewählt:"
		},
		uploader: {
			chooseMessage: "Ziehen Sie Ihre Datei hierher oder klicken Sie auf die Schaltfläche, um eine Datei auszuwählen.",
			chooseButton: "Datei auswählen",
			addFile: "Neue Datei hinzufügen",
			count: "Ausgewählte {label} Dateien",
			allowExtensions: "Akzeptierte Formate",
			errors: {
				invalidType: "Dateiformat für {label} ist ungültig",
				duplicate: "Datei {label} wurde bereits ausgewählt",
				longName: "Die maximale zulässige Dateinamenlänge beträgt 50 Zeichen"
			}
		},
		common: {
			cd: {
				warning: "Sehr geehrter Benutzer, Sie löschen derzeit Daten! Wenn Sie sich sicher bei dieser Operation sind, klicken Sie auf die Schaltfläche 'Daten löschen'.",
				ok: "Daten löschen",
				cancel: "Abbrechen und zurückkehren"
			}
		},
		form: {
			checkbox: {
				yes: "Ja",
				no: "Nein"
			},
			title: {
				require: "Das Feld '{label}' ist erforderlich",
				unique: "Das Feld '{label}' muss eindeutig sein",
				requireUnique: "Das Feld '{label}' ist erforderlich und muss eindeutig sein"
			},
			generator: {
				entityName: "Standardformular",
				head: {
					controls: "Felder",
					preview: "Vorschau",
					config: "Einstellungen",
					patterns: "Muster"
				},
				controls: {
					text: "Textfeld",
					number: "Nummernfeld",
					textarea: "Textbereich",
					password: "Passwortfeld",
					select: "Mehrfachauswahlfeld",
					checkdrop: "Ja-Nein-Feld",
					color: "Farbfeld",
					datePicker: "Datumsfeld",
					timePicker: "Zeitfeld",
					signaturePad: "Digitale Signatur",
					textEditor: "Texteditor",
					hr: "Trennlinie",
					h3: "Titel",
					lable: "Hilfetext",
					removeConfirm: "Möchten Sie wirklich löschen?",
					defaultValue: "Standardwert"
				},
				config: {
					label: "Titel",
					isUnicode: "Erlaube persisches Tippen",
					select: {
						head: "Auswahlen",
						text: "Titel",
						value: "Wert",
						textNumber: "Wählen Sie Nummer {label}",
						serverAddress: "Serveradresse"
					},
					patterns: {
						required: "Feld ist erforderlich",
						mobile: "Mobiltelefonmuster",
						nationalCode: "Nationaler ID-Muster",
						email: "E-Mail-Muster"
					}
				}
			},
			select: {
				noOption: "Kein Wert zum Anzeigen gefunden."
			}
		},
		textEditor: {
			table: {
				rowCount: "Anzahl der Zeilen",
				colCount: "Anzahl der Spalten",
				create: "Tabelle erstellen"
			}
		},
		camera: {
			open: "Mit Kamera verbinden",
			close: "Kamera trennen",
			take: "Bild aufnehmen"
		},
		popup: {
			closeConfirm: "Möchten Sie wirklich schließen?"
		}
	}
}

export default de
