<template>
	<div class="row" v-if="localEntity">
		<div class="col-12" v-if="useMultilanguage && languages.length > 1 && translationPropsLength > 0">
			<ul class="nav nav-tabs">
				<li class="nav-item" v-for="(lang,index) in languages" :key="index">
					<button class="nav-link" @click="currentLanguage = lang.id" v-bind:class="{'active':currentLanguage == lang.id}">
						{{$loc.fromResource(`languages.${lang.id}`)}}
					</button>
				</li>
			</ul>
			<div class="tab-content">
				<div class="tab-pane fade" v-bind:class="{'show active':currentLanguage == lang.id}" v-for="(lang,index) in languages" :key="index">
					<form-collapse :mode="mode"
								   :col="col"
								   :use-validation="useValidation"
								   :display-type="displayType"
								   :entity="localEntity[lang.id]"
								   :traslation-mode="lang.id != primaryLanguage"
								   v-on:change="(x) => {$emit('change', x, lang.id)}"
								   v-on:generator-up="(x) => {$emit('generator-up', x)}"
								   v-on:generator-down="(x) => {$emit('generator-remove', x)}"
								   v-on:generator-remove="(x) => {$emit('generator-remove', x)}" />
				</div>
			</div>
		</div>
		<div v-else class="col-12">
			<form-collapse :mode="mode"
						   :col="col"
						   :use-validation="useValidation"
						   :display-type="displayType"
						   :entity="localEntity[primaryLanguage]"
						   v-on:change="(x) => {$emit('change', x)}"
						   v-on:generator-up="(x) => {$emit('generator-up', x)}"
						   v-on:generator-down="(x) => {$emit('generator-remove', x)}"
						   v-on:generator-remove="(x) => {$emit('generator-remove', x)}" />
		</div>
		<div class="col-12">
			<slot></slot>
		</div>
		<div class="col-12" v-if="entityHasError">
			<div class="alert alert-danger">
				{{ $loc.fromResource('error.checkForm') }}
			</div>
		</div>

		<div class="col-12" v-if="task">
			<kaveh-button @click="submit" @mousedown="getPropsForGenerator" class="btn btn-dark btn-sm" :is-loading="localIsLoading || isLoading">
				{{ $loc.fromResource(submitResourceKey) }}
			</kaveh-button>
		</div>
	</div>
</template>

<script>
	import formCollapse from './form-collapse'
	import { BaseEntity } from '@/core/data/entities'

	export default {
		emits: ['change', 'generator-remove', 'generator-up', 'generator-down'],

		components:
		{
			'form-collapse': formCollapse
		},

		props:
		{
			'entity': {
				type: Function
			},

			'props': {
				type: Object
			},

			'mode': {
				type: String
			},

			'task': {
				type: Function
			},

			'value': {
				type: Object
			},

			'useValidation': {
				type: Boolean
			},

			'useMultilanguage': {
				type: Boolean
			},

			'isLoading': {
				type: Boolean
			},

			'col': {
				type: String
			},

			'displayType': {
				type: String
			},

			'submitResourceKey': {
				type: String
			},
		},

		data()
		{
			return {
				ver: 0,
				localIsLoading: false,
				entityHasError: false,
				localEntity: {},

				translations: [],
				currentLanguage: undefined,
			}
		},


		computed:
		{
			languages()
			{
				return this.$store.state.app.config.languages
			},

			primaryLanguage()
			{
				return this.languages[0].id
			}
		},

		beforeMount()
		{
			for (var i = 0; i < this.languages.length; i++)
			{
				var lang = this.languages[i].id;
				var value = this.value;

				if (value)
				{
					if (this.primaryLanguage != lang)
					{
						if (value['translations'])
						{
							const translations =
								JSON.parse(value['translations'] || "[]");

							const index = translations.findIndex((x) =>
							{
								return x.lang == lang
							})

							value = index > -1 ? translations[index] : {}
						}
						else
						{
							value = {}
						}
					}
				}

				if (this.props == undefined)
				{
					this.localEntity[lang] = new this.entity({ value: value });
				}

				else
				{
					var baseEntity = new BaseEntity();
					baseEntity.props = this.props;

					if (value)
					{
						baseEntity.setValue(value)
					}

					this.localEntity[lang] = baseEntity
				}
			}

			this.currentLanguage = this.primaryLanguage
			this.translationPropsLength = this.localEntity[this.primaryLanguage].getTranslationProps().length
		},

		methods: {
			getValue(lang, justMultiLanguages)
			{
				lang = lang || this.primaryLanguage;

				if (this.useValidation)
				{
					this.localEntity[lang].cleanError();
					this.localEntity[lang].parseFormError(undefined, justMultiLanguages);

					const errorLength =
						this.localEntity[lang].getFormErrorLength(undefined, justMultiLanguages);

					if (errorLength > 0)
					{
						this.entityHasError = true
						return undefined
					}
				}

				return this.localEntity[lang].getFormValue()
			},

			getErrorLength(lang, justMultiLanguages)
			{
				lang = lang || this.primaryLanguage;

				if (this.useValidation)
				{
					this.localEntity[lang].cleanError();
					this.localEntity[lang].parseFormError(undefined, justMultiLanguages);

					const errorLength =
						this.localEntity[lang].getFormErrorLength(undefined, justMultiLanguages);

					if (errorLength > 0)
					{
						return errorLength
					}
				}

				return 0;
			},

			async getPropsForGenerator(e)
			{
				if (e.which == 2)
				{
					const lang = this.primaryLanguage;
					const flatProps = this.localEntity[lang].getFlatProps();

					var input = document.createElement("input");

					input.type = "text"
					input.value = JSON.stringify(flatProps)

					input.select();
					navigator.clipboard.writeText(input.value);

					alert("The form template was successfully saved in clipboard");
				}
			},

			handleChange(x, lang)
			{
				this.$emit('change', x)
			},

			async submit()
			{
				if (this.task)
				{
					this.entityHasError = false;
					this.currentLanguage = this.primaryLanguage;

					const pLang = this.primaryLanguage;
					const translations = [];

					if (this.useValidation)
					{
						if (this.getErrorLength(pLang) > 0)
						{
							this.entityHasError = true
							return
						}

						for (var i = 0; i < this.languages.length; i++)
						{
							const lang =
								this.languages[i].id;

							if (lang != this.primaryLanguage)
							{
								const value =
									this.localEntity[lang].getFormValue();

								if (Object.keys(value || {}).length > 0)
								{
									if (this.getErrorLength(lang, true) > 0)
									{
										this.entityHasError = true;
										this.currentLanguage = lang;
										break;
									}
									else
									{
										translations.push({
											lang: lang, ...value
										});
									}
								}
							}
						}
					}


					if (this.entityHasError == false)
					{
						try
						{
							var value =
								this.localEntity[pLang].getFormValue()

							if (translations.length > 0)
							{
								value.translations = JSON.stringify(translations);
							}

							this.localIsLoading = true;
							await this.task(value);

						} catch (e)
						{
							console.log('form Error:' + e)
						}
					}

					this.localIsLoading = false;
				}
			}
		}
	}
</script>
