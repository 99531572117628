<template>
	<div dir="ltr" id="CodeEditor" class="kaveh-code-editor"></div>
</template>
<script>
	import '@/assets/fonts/consolas/consolas.css'

	import { keymap } from '@codemirror/view';
	import { indentUnit } from "@codemirror/language"
	import { basicSetup, EditorView } from "codemirror"
	import { EditorState, Compartment } from "@codemirror/state"
	import { javascript } from "@codemirror/lang-javascript"

	var codeEditor = null;

	export default {
		emit: ['change', 'update:modelValue'],

		props: {
			prop: {
				type: Object
			}
		},


		data()
		{
			return {
				value: null,
			}
		},

		async mounted()
		{
			codeEditor = new EditorView({
				parent: document.getElementById('CodeEditor')
			})

			this.setState(this.value || '')
		},

		methods: {

			onHandelChange()
			{
				if (codeEditor)
				{
					const value = codeEditor.state.doc.toString();
					this.$emit('update:modelValue', value);
					this.$emit('change', value)
				}
			},

			setValue(value)
			{
				if (codeEditor)
				{
					this.setState(value)
					this.$emit('update:modelValue', value);
				}
			},

			setState(value)
			{
				var language = new Compartment();

				codeEditor.setState(EditorState.create({
					doc: value,
					extensions: [
						basicSetup,
						indentUnit.of("	"),
						language.of(javascript()),
						EditorView.updateListener.of((e) =>
						{
							this.onHandelChange()
						})
					]
				}))
			}
		}
	}
</script>
<style>
	.kaveh-code-editor {
		width: 100%;
		min-height: 300px;
		background: #fff;
		border-radius: 4px;
	}

		.kaveh-code-editor .cm-scroller {
			font-family: Consolas,IRANSansX;
			font-size:13px;
		}
</style>
