<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { PersonNationality, PersonNationalityFilter } from '@/modules/hub/data/person-nationality/list'
	import { PersonNationalityService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonNationality;
			},
			
			filterEntity()
			{
				return PersonNationalityFilter;
			},

			service()
			{
				return new PersonNationalityService();
			},

			actions()
			{
				return [
					{
						routeName: 'hub.personNationality.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'hub.personNationality.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'hub.personNationality.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						resourceKey: 'action.update',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'hub.personNationality.personRealsUpdate',
								resourceKey: 'hub.personNationalityPersonRealsUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'hub.personNationality.update',
								resourceKey: 'hub.personNationalityUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							}
						],
					},

					{
						routeName: 'hub.personNationality.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'hub.personNationality.detail',
							resourceKey: 'hub.personNationalityDetail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>