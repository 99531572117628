//GeneratorFlag:[NoChange]
export default {
	path: 'advertise',
	name: 'cms.advertise.layout',
	redirect: to => {
		return {name: 'cms.advertise.list'};
	},
	component: () => import('@/modules/cms/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/cms/advertise"],
		resourceKey: 'cms.advertiseLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[AdvertiseCreate]"
		{
			path: 'create',
			name: 'cms.advertise.create',
			component: () => import('@/modules/cms/views/advertise/advertise-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.advertiseCreate',
				clientAccessKeys:["/cms/advertise/create"],
				serverAccessKeys:["/cms/advertise/create","/cms/advertise/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[AdvertiseCreateImport]"
		{
			path: 'create-import',
			name: 'cms.advertise.createImport',
			component: () => import('@/modules/cms/views/advertise/advertise-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.advertiseCreateImport',
				clientAccessKeys:["/cms/advertise/create-import"],
				serverAccessKeys:["/cms/advertise/create","/cms/advertise/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[AdvertiseDelete]"
		{
			path: 'delete',
			name: 'cms.advertise.delete',
			component: () => import('@/modules/cms/views/advertise/advertise-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.advertiseDelete',
				clientAccessKeys:["/cms/advertise/delete"],
				serverAccessKeys:["/cms/advertise/delete","/cms/advertise/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[AdvertiseDetail]"
		{
			path: 'detail',
			name: 'cms.advertise.detail',
			component: () => import('@/modules/cms/views/advertise/advertise-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.advertiseDetail',
				clientAccessKeys:["/cms/advertise/detail"],
				serverAccessKeys:["/cms/advertise/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[AdvertiseList]"
		{
			path: 'list',
			name: 'cms.advertise.list',
			component: () => import('@/modules/cms/views/advertise/advertise-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.advertiseList',
				clientAccessKeys:["/cms/advertise/list"],
				serverAccessKeys:["/cms/advertise/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[AdvertiseUpdate]"
		{
			path: 'update',
			name: 'cms.advertise.update',
			component: () => import('@/modules/cms/views/advertise/advertise-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.advertiseUpdate',
				clientAccessKeys:["/cms/advertise/update"],
				serverAccessKeys:["/cms/advertise/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}