<template>
	<div class="kaveh-file-uploader" :style="{'background': background}">
		<div class="body">
			<div class="choose" v-if="items.length == 0">
				<div>{{$loc.fromResource('com.uploader.chooseMessage')}}</div>
				<label class="btn btn-outline-dark mt-5" @click="chooseFile">
					{{$loc.fromResource('com.uploader.chooseButton')}}
				</label>
			</div>
			<div class="files" v-else>
				<div class="row pb-3">
					<div class="col pt-2">
						<label>{{$loc.fromResource('com.uploader.count', {'label': items.length})}}</label>
					</div>
					<div class="col text-end">
						<label class="btn btn-light border" @click="chooseFile">
							{{$loc.fromResource('com.uploader.addFile')}}
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-12" v-for="(item, index) in items" :key="index">
						<div class="file">
							<strong class="me-2">{{ index + 1 }}.</strong>
							<kaveh-icon-bootstrap :name="getIcon(item.type)"
												  color="var(--kaveh-base-color)"
												  class="me-3"
												  size="30px" />
							<span dir="ltr">{{ item.name }}</span>&nbsp;
							<small class="text-muted" dir="ltr">({{ Math.round(item.size/1024) }} kb)</small>
							<button type="button" class="btn float-end" @click="items.splice(index,1)">
								<kaveh-icon-bootstrap color="red" name="bi-trash" size="16px" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="extensions">
				<span class="text-muted float-start">{{$loc.fromResource('com.uploader.allowExtensions')}}</span>
				<span class="text-muted float-end">{{ stringExtensions }}</span>
			</div>
		</div>
	</div>
</template>
<script>
	import { toast } from '@/plugins'
	import { fileExtensions } from '@/core/data/statics'
	export default {
		emit: ['change', 'update:modelValue'],

		props: {
			background: {
				type: String,
				default: "#f8f8f8",
			},

			accept: {
				type: Array,
				default: undefined
			},

			isMultiple: {
				type: Boolean,
				default: true
			},

			outputType: {
				type: String,
				default: 'base64'
			},

			task: {
				type: Function
			}
		},

		data()
		{
			return {
				isloading: false,
				extensions: [],
				stringExtensions: "",
				items: [],
			}
		},

		beforeMount()
		{
			this.init();
		},

		methods:
		{

			init()
			{
				var result = [];
				if (this.accept == undefined)
				{
					result = fileExtensions;
				}
				else
				{
					for (var i = 0; i < fileExtensions.length; i++)
					{
						if (this.accept.findIndex((x) => { return x == fileExtensions[i].contentType }) > -1)
						{
							result.push(fileExtensions[i])
						}
					}
				}

				this.stringExtensions = result.map(u => u.name).join(', ')
				this.extensions = result;
			},

			chooseFile()
			{
				var input =
					document.createElement("INPUT");

				input.setAttribute("type", "file");

				if (this.isMultiple)
				{
					input.setAttribute("multiple", "multiple");
				}

				input.onchange = async () =>
				{
					var files = [];
					for (var i = 0; i < input.files.length; i++)
					{
						const file =
							input.files[i];

						if (this.fileIsValid(file) == false)
						{
							const message =
								this.$loc.fromResource(
									'com.uploader.errors.invalidType', { label: file.name }
								);

							toast.error(message);

							continue;
						}

						if (this.fileIsDuplicate(file) == true)
						{
							const message =
								this.$loc.fromResource(
									'com.uploader.errors.duplicate', { label: file.name }
								);

							toast.error(message);

							continue;
						}

						if (this.fileNameIsLong(file) == true)
						{
							const message =
								this.$loc.fromResource(
									'com.uploader.errors.longName', { label: file.name }
								);

							toast.error(message);

							continue;
						}


						var output = {
							name: file.name,
							type: file.type,
							size: file.size,
						}

						if (this.outputType == 'base64')
						{
							output.dataUrl = await this.toDataUrl(file)
						}

						else if (this.outputType == 'file')
						{
							output.file = file
						}

						files.push();

					}

					this.items = [...this.items, ...files];

					const value = this.getValue();
					this.$emit('update:modelValue', value);
					this.$emit('change', value)
				}

				input.click();
			},

			fileIsValid(file)
			{
				const index = this.extensions.findIndex((x) =>
				{
					return x.contentType == file.type;
				});

				return index > -1;
			},

			fileIsDuplicate(file)
			{
				const index = this.items.findIndex((x) =>
				{
					return x.type == file.type && x.name == file.name && x.size == file.size;
				});

				return index > -1;
			},

			fileNameIsLong(file)
			{
				return file.name.length > 50
			},

			getIcon(type)
			{
				const extention = fileExtensions.find((x) =>
				{
					return x.contentType == type
				});

				return extention ? extention.icon : "";
			},

			async toDataUrl(file)
			{
				return new Promise((resolve, reject) =>
				{
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				});
			},


			getValue()
			{
				var value = [];

				for (var i = 0; i < this.items.length; i++)
				{
					const item = this.items[i];

					value.push({
						fileName: item.name,
						contentType: item.type,
						contentLength: item.size,
						dataUrl: item.dataUrl
					})
				}

				return this.items;
			}
		}
	}
</script>
<style>

	.kaveh-file-uploader {
		border-radius: 5px;
		border: #ddd solid 1px;
	}

		.kaveh-file-uploader,
		.kaveh-file-uploader .body .choose,
		.kaveh-file-uploader .body .files {
			width: 100%;
		}

			.kaveh-file-uploader .body {
				min-height: 300px;
				background: #fff;
				border-radius: 5px;
			}

				.kaveh-file-uploader .body .choose {
					color: #000;
					padding-top: 100px;
					text-align: center;
					font-size: 15px;
				}

					.kaveh-file-uploader .body .choose .btn {
						padding: 10px 30px;
					}

				.kaveh-file-uploader .body .files,
				.kaveh-file-uploader .body .files .file {
					padding: 10px;
				}

					.kaveh-file-uploader .body .files .file {
						min-width: 100%;
						min-height: 40px;
						margin-bottom: 10px;
						border: #e9e9e9 solid 1px;
						border-radius: 10px;
					}


			.kaveh-file-uploader .footer {
				min-height: 40px;
				padding: 10px;
				border-radius: 5px;
				font-size: 11px;
			}
</style>
