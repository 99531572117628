<template>
	<div class="table-kaveh">

		<table-actions v-if="actions"
					   :actions="actions"
					   :checkedLen="checkedLen"
					   :filterLen="filterLen"
					   :displayType="displayType"
					   :useFilter="filterEntity != undefined"
					   v-on:selectAction="selectAction"
					   v-on:exportExcel="exportExcel"
					   v-on:createReport="isShowReport = true"
					   v-on:showFilter="isShowFilter = !isShowFilter"
					   v-on:cleanFilter="cleanFilterAndInitTable"
					   v-on:displayChange="setDisplay" />


		<table-filter-block v-if="isShowFilter" />

		<table-filter v-on:flterPropChange="flterPropChange"
					  v-bind:entity="localEntity" />

		<table-loading v-if="getComponent() == 'loading'" />
		<table-empty v-else-if="getComponent() == 'empty'" />
		<table-error v-else-if="getComponent() == 'error'" />

		<table-grid v-else-if="getComponent() == 'grid'"
					v-on:changePage="changePage"
					v-on:changeOrder="changeOrder"
					v-on:selectFilterProp="selectFilterProp"
					v-on:checked="(x) => checkedLen = x.length"
					v-bind:items="items"
					v-bind:entity="localEntity"
					v-bind:totalRows="totalRows"
					v-bind:totalfilters="totalfilters"
					v-bind:currentPage="currentPage"
					v-bind:selectable="selectable"
					v-bind:pageSize="pageSize"
					v-bind:showError="showError"
					v-bind:showPagination="showPagination"
					v-bind:showForeignKey="showForeignKey"
					v-bind:showRowNumber="showRowNumber"
					v-bind:loading="internalLoading" />

		<table-grid-list v-else-if="getComponent() == 'grid-list'"
						 v-on:changePage="changePage"
						 v-on:changeOrder="changeOrder"
						 v-on:checked="(x) => checkedLen = x.length"
						 v-bind:orderPropertyName="orderPropertyName"
						 v-bind:orderDescending="orderDescending"
						 v-bind:items="items"
						 v-bind:entity="localEntity"
						 v-bind:totalRows="totalRows"
						 v-bind:totalfilters="totalfilters"
						 v-bind:currentPage="currentPage"
						 v-bind:selectable="selectable"
						 v-bind:pageSize="pageSize"
						 v-bind:showError="showError"
						 v-bind:showForeignKey="showForeignKey"
						 v-bind:showRowNumber="showRowNumber" />

		<table-tree v-else-if="getComponent() == 'tree'"
					v-on:changePage="changePage"
					v-on:checked="(x) => checkedLen = x.length"
					v-bind:items="items"
					v-bind:entity="localEntity"
					v-bind:totalRows="totalRows"
					v-bind:totalfilters="totalfilters"
					v-bind:currentPage="currentPage"
					v-bind:selectable="selectable"
					v-bind:pageSize="pageSize"
					v-bind:task="task"
					v-bind:showError="showError"
					v-bind:showForeignKey="showForeignKey"
					v-bind:showRowNumber="showRowNumber" />


		<kaveh-popup v-if="isShowReport" :close="closeReportPopup">

			<div v-if="reportState == 'form'">
				<kaveh-form :entity="entity"
							:validation="false"
							mode="filter"
							:value="filterValue"
							:task="createReport"
							submit-resource-key="action.downloadReport" />
			</div>

			<div v-if="reportState == 'progress'">
				<table-report-progress :totalPartLength="reportTotalPartLength"
									   :currentPartLength="reportCurrentPartLength"
									   :duration="reportDuration" />
			</div>

			<div v-if="reportState == 'done'">
				<div class="mt-5 text-center">
					<kaveh-icon-bootstrap name="bi-check-circle-fill" size="50px" color="green" />
					<div class="mt-4 mb-3">{{$loc.fromResource('com.table.report.done')}}</div>
					<button class="btn btn-light border" @click="closeReportPopup">{{$loc.fromResource('action.close')}}</button>
				</div>
			</div>

		</kaveh-popup>

	</div>

</template>
<script>

	import { ENUMS } from '@/core/data'
	import { deviceType } from '@/plugins'
	import { BaseEntity } from '@/core/data/entities'
	import { utils, writeFile } from 'xlsx'


	import TableEmpty from './common/table-empty'
	import TableError from './common/table-error'
	import TableLoading from './common/table-loading'
	import TableFilter from './common/table-filter'
	import TableFilterBlock from './common/table-filter-block'
	import TableReportProgress from './report/table-report-progress'
	import TableActions from './actions/table-actions'
	import TableGrid from './table-grid'
	import TableGridList from './table-grid-list'
	import TableTree from './table-tree'

	var props = null;
	export default {

		components: {
			'table-actions': TableActions,
			'table-empty': TableEmpty,
			'table-error': TableError,
			'table-loading': TableLoading,
			'table-grid': TableGrid,
			'table-filter': TableFilter,
			'table-filter-block': TableFilterBlock,
			'table-grid-list': TableGridList,
			'table-tree': TableTree,
			'table-report-progress': TableReportProgress,
		},

		props:
		{
			'props': {
				type: Object
			},

			'entity': {
				type: Function
			},

			'filterEntity': {
				type: Function
			},

			'task': {
				type: Function
			},

			'actions': {
				type: Array
			},

			'data': {
				type: Object
			},

			'filterData': {
				type: Object
			},

			'selectable': {
				type: Boolean,
				default: true,
			},

			'showError': {
				type: Boolean,
				default: false,
			},

			'showRowNumber': {
				type: Boolean,
				default: true
			},

			'showForeignKey': {
				type: Boolean,
				default: false
			},

			'showPagination': {
				type: Boolean,
				default: true
			},

			'showFilter': {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				items: [],
				orderPropertyName: '',
				orderDescending: false,
				pageSize: 10,
				totalRows: 0,
				totalfilters: 0,
				checkedLen: 0,
				filterLen: 0,
				currentPage: 1,
				component: 'loading',
				displayType: 'grid',
				reportState: 'form',
				loading: false,
				internalLoading: false,
				localData: null,
				filterValue: null,
				isShowFilter: this.showFilter,
				isShowReport: false,
				hasError: false,
				localEntity: null,
				reportTotalPartLength: 0,
				reportCurrentPartLength: 1,
				reportDuration: 0,
				filterProp: null,
				filterPropValue: null,
			}
		},

		beforeMount()
		{
			if (this.props == undefined)
			{
				this.localEntity = this.entity;
			}
			else
			{
				props = this.props
				this.localEntity = function (arg)
				{
					arg = arg || {};
					arg.props = props

					return new BaseEntity(arg);
				}
			}

			this.displayType = deviceType() == 'desktop' ? 'grid' : 'grid-list';
			this.initFilter()
			this.init();
		},


		methods:
		{
			getPayload()
			{

				var filter = {};
				var query = this.$route.query
				var pageSize = Number(query.pageSize) || 10;
				var currentPage = Number(query.currentPage) || 1;
				var filterKeys = Object.keys(this.$route.query);

				var payload = {
					length: pageSize,
					pageSize: pageSize,
					currentPage: currentPage,
					start: ((currentPage - 1) * pageSize)
				}

				for (var i = 0; i < filterKeys.length; i++)
				{
					const key = filterKeys[i];
					if (["start", "length", "pageSize", "currentPage", "temp"].indexOf(key) == -1)
					{
						filter[key] = this.$route.query[key]
					}
				}

				if (Object.keys(filter).length > 0)
				{
					const entity = new this.entity({
						value: filter
					});

					payload.filter = entity.getValue();
				}

				const orderPropertyName = query['orderPropertyName']
				const orderDescending = query['orderDescending']

				if (orderPropertyName)
				{
					payload.order = {
						propertyName: orderPropertyName,
						desc: orderDescending == 'true' ? true : false
					}
				}

				return payload
			},

			async flterPropChange()
			{
				if (this.filterProp)
				{
					var paylpad = {};
					var prop = this.filterProp;
					paylpad[prop.name] = this.filterPropValue;

					await this.cleanFilter();
					this.filterChange(paylpad);
				}
			},

			async filterChange(payload)
			{
				this.filterValue = payload
				this.length = Object.keys(payload || {}).length;

				const query = {
					...payload
				};

				await this.$router.replace({ query });

				this.initFilter()
				this.init();
			},

			async cleanFilter(cleanFilterProp)
			{
				var query = {};
				const keys = Object.keys(this.$route.query);

				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					if (["start", "length", "pageSize", "currentPage"].indexOf(key) > -1)
					{
						query[key] = this.$route.query[key];
					}
				}

				if (cleanFilterProp)
				{
					this.filterProp = null;
					this.filterPropValue = null;
				}

				await this.$router.replace({ query });
				this.initFilter()
			},

			async cleanFilterAndInitTable()
			{

				await this.cleanFilter(true);
				this.init();
			},


			initFilter()
			{
				var value = {};
				var length = 0;
				const keys = Object.keys(this.$route.query);

				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					if (["start", "length", "pageSize", "currentPage", "orderPropertyName", "orderDescending"].indexOf(key) == -1)
					{
						value[key] = this.$route.query[key];
						length += 1;
					}
				}

				this.filterValue = value
				this.filterLen = length
			},

			selectFilterProp(prop)
			{
				this.filterProp = prop;
				this.filterPropValue = ""
			},

			async createReport(payload)
			{
				this.reportState = 'progress'

				var items = []
				var total = 0;
				var length = 1000;

				var payload = {
					start: 0,
					length: length,
					filter: payload
				};

				do
				{
					var startDateTime = Date.now();
					var data = await this.getData(payload);

					for (var item of data.items || [])
					{
						var entity =
							new this.localEntity({ value: item })

						items.push(entity);
					}

					total = data.total;
					payload.start += length;

					this.reportTotalPartLength = (data.total / length);
					this.reportCurrentPartLength = (items.length / length);
					this.reportDuration = (((total - items.length) / length) * (Date.now() - startDateTime));

				} while (items.length < total);

				this.reportState = 'done'
				this.exportExcel(items)
			},

			closeReportPopup()
			{
				this.isShowReport = false;
				this.reportState = 'form'
			},

			async init(payload, loadingVar)
			{

				try
				{
					loadingVar = loadingVar || 'loading'
					payload = payload || this.getPayload();

					this[loadingVar] = true;

					var items = [];
					var data = await this.getData(payload);

					if (data)
					{
						for (var item of data.items || [])
						{
							var entity =
								new this.localEntity({ value: item })

							if (this.showError == true)
							{
								entity.parseAllError();
							}

							items.push(entity);
						}

						this.items = items;
						this.totalRows = data.total;
						this.totalfilters = data.filtered;
						this.pageSize = payload.pageSize;
						this.currentPage = payload.currentPage;
					}
				} catch (e)
				{
					this.hasError = true;
				}

				this[loadingVar] = false;
			},


			async getData(payload)
			{
				var data;
				payload = payload || {
					start: 0, length: 10, filter: {}
				};

				if (this.filterData)
				{
					payload.filter = Object.assign({}, payload.filter, this.filterData);
				}

				if (this.data != null)
				{
					data = {
						total: (this.data || []).length,
						items: (this.data || []).slice(payload.start, payload.start + payload.length)
					}
				}
				else
				{
					try
					{
						const res = await this.task(payload);
						data = res.data
					} catch (e)
					{
						console.log('table error:' + e)
					}
				}

				data = data || {};
				data.total = data.total || 0;
				data.items = data.items || [];
				data.filtered = data.filtered || 0;

				if (data.items.length == 0 && payload.start > 1)
				{
					const query = {
						...this.$route.query, currentPage: 1
					};

					await this.$router.replace({ query });
					await this.init();
					return;
				}

				this.localData = data;
				return data;
			},

			exportExcel(items)
			{
				items = items || this.items;

				var wb = utils.book_new();
				var entity = new this.localEntity(null);

				var sheets = [{
					name: entity.name,
					displayName: this.$loc.fromResource(entity.resourceKey),
					data: []
				}];

				for (var item of items)
				{
					for (var key in item.props)
					{
						const prop = item.props[key];

						if (prop.type == ENUMS.PROP_TYPE.OBJECT)
						{
							if (prop.entity.schema == "side")
							{
								let index =
									sheets.findIndex((x) =>
									{
										return x.name == prop.name
									});

								if (index == -1)
								{
									sheets.push({
										name: prop.name,
										displayName: this.$loc.fromResource(prop.resourceKey),
										data: prop.displayValue ? [prop.displayValue] : []
									});

									continue;
								}

								if (prop.displayValue)
								{
									sheets[index].data.push(prop.displayValue);
								}
							}
						}
					}

					sheets[0].data.push(item.displayValue);
				}


				for (const item of sheets)
				{
					utils.book_append_sheet(
						wb, utils.json_to_sheet(item.data), item.displayName
					)
				}

				wb.Workbook = {
					Views: [{ RTL: true }]
				}

				writeFile(wb, `${entity.name}-export-${Date.now().toString()}.xlsx`)
			},


			async selectAction(action)
			{
				var checkeds = this.getCheckeds();
				var activeMode = action.activeMode;

				if (action.task && typeof (action.task) == 'function')
				{
					if (action.task.constructor.name == 'AsyncFunction')
					{
						await action.task(checkeds);
					}
					else
					{
						action.task(checkeds);
					}
				}

				else if (action.routeName)
				{
					var query = {};
					var routeParams = action.routeParams;
					var dataTransferMode = action.dataTransferMode;
					var dataTransferKeys = action.dataTransferKeys;

					if (dataTransferMode == 'store')
					{
						query['temp'] = await this.addToTemp(
							activeMode == 'single'
						);
					}

					if (dataTransferMode == 'query' && dataTransferKeys)
					{
						for (var key of dataTransferKeys)
						{
							query[key] = checkeds[0][key]
						}
					}

					if (routeParams)
					{
						query = {
							...query,
							...routeParams
						}
					}

					this.$router.push({
						name: action.routeName, query: query
					})
				}
			},


			async addToTemp(single)
			{
				const checkeds = this.getCheckeds()

				if (single)
				{
					return await this.$store.dispatch('temp/add', checkeds[0]);
				}

				return await this.$store.dispatch('temp/add', checkeds);
			},

			getCheckeds()
			{
				var checkeds = [],
					index = this.getStartRowIndex();

				for (var item of this.items)
				{
					if (item.checked)
					{
						var value = item.value;
						value['rowIndex'] = index

						checkeds.push(value);
					}

					index += 1;
				}

				return checkeds
			},


			async changePage()
			{
				await this.init(null, 'internalLoading');
			},

			async changeOrder()
			{
				await this.init(null, 'internalLoading');
			},

			getStartRowIndex()
			{
				const pageSize = this.pageSize;
				const currentPage = this.currentPage;

				return ((currentPage - 1) * pageSize);
			},


			setDisplay(displayType)
			{
				this.displayType = displayType
			},


			getComponent()
			{
				if (this.loading)
				{
					return "loading"
				}

				if (this.hasError)
				{
					return "error"
				}

				if (this.items.length == 0)
				{
					return "empty"
				}

				return this.displayType
			},
		},
	}
</script>
<style>

	.table-kaveh td, table-kaveh th {
		padding: 6px;
	}

	.table-kaveh .cell-popup-box table td {
		padding: 0;
	}

	.table-kaveh th, .table-kaveh td {
		vertical-align: middle;
	}

	.table-kaveh:not(.table-borderless) th, .table-kaveh:not(.table-borderless) td {
		border-left: #dfdfdf solid 1px;
	}

	.table-kaveh th:last-child, .table-kaveh td:last-child {
		border-left: none;
	}

	.table-kaveh th, .table-kaveh td {
		border-bottom: none;
	}

	.table-kaveh .truncate {
		width: auto;
		min-width: 0;
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		display:table-cell;
		white-space: nowrap;
		font-size: 13px;
	}

	.table-kaveh tr.checked > td {
		font-weight: 500;
		color: #ff6a00;
	}

	[dir="ltr"] .table-kaveh:not(.table-borderless) th, .table-kaveh:not(.table-borderless) td {
		border-left: unset;
		border-right: #dfdfdf solid 1px;
	}

	[dir="ltr"] .table-kaveh th:last-child, .table-kaveh td:last-child {
		border-left: unset;
		border-right: none;
	}

</style>
