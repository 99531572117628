//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class PersonService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Person.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/create', params || { });
	};

	//GeneratorFlag:[Person.Delete]
	async delete(personId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:personId
		}
	
		return await _server.delete('/hub/person/delete', {'params':params})
	};

	//GeneratorFlag:[Person.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/person/detail', {'params':params});
	};

	//GeneratorFlag:[Person.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/person/list', {'params':params});
	};

	//GeneratorFlag:[Person.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/update', params || { });
	};

	//GeneratorFlag:[Person.ChangePassword]
	async changePassword(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/change-password', params || { });
	};

	//GeneratorFlag:[Person.EmployeeList]
	async employeeList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/person/employee-list', {'params':params});
	};

	//GeneratorFlag:[Person.RncExistRegister]
	async rncExistRegister(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/rnc-exist-register', params || { });
	};

	//GeneratorFlag:[Person.RncInquiry]
	async rncInquiry(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/rnc-inquiry', params || { });
	};

	//GeneratorFlag:[Person.RncResetPassword]
	async rncResetPassword(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/rnc-reset-password', params || { });
	};

	//GeneratorFlag:[Person.GuestCreate]
	async guestCreate(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/guest-create', params || { });
	};

	//GeneratorFlag:[Person.RemResetPassword]
	async remResetPassword(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person/rem-reset-password', params || { });
	};

//GeneratorFlag:[NewAction]
}