import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Localization from '../localization'
export default class Router
{
	constructor(modules)
	{
		this.modules = modules || [];
		this.loc = new Localization();
		this.layout = store.state.app.layout

		this.routes = [
			{
				path: '/',
				name: 'dashboardLayout',
				component: () => import(`/src/views/${this.layout}/shareds/layout.vue`),
				meta: {
					requiresAuth: true,
					resourceKey: 'page.home',

					menuConfig: {
						isVisible: true,
						isExpand: true,
						isBlank: true,
						alwaysIsExpand: true,
						iconName: 'bi-layout-wtf',
					}
				},
				children: [],
			},
			{
				name: 'auth',
				path: '/auth',
				component: () => import('@/views/default/auth/Auth.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.auth',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'notFound',
				path: '/:catchAll(.*)',
				component: () => import('@/views/not-found.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.notFound',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'accessDenied',
				path: '/access-denied',
				component: () => import('@/views/access-denied.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.accessDenied',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'whatsNew',
				path: '/whats-new',
				component: () => import('@/views/whats-new.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.whatsNew',

					menuConfig: {
						visible: false
					}
				}
			},
			{
				name: 'print',
				path: '/print',
				component: () => import('@/views/print-page.vue'),
				meta: {
					requiresAuth: false,
					resourceKey: 'page.print',

					menuConfig: {
						visible: false
					}
				}
			},
		]

		for (var i = 0; i < modules.length; i++)
		{
			for (var ii = 0; ii < modules[i].routes.length; ii++)
			{
				this.routes[0].children.push(modules[i].routes[ii])
			}
		}
	}

	create()
	{
		const router = createRouter({
			history: createWebHistory(),
			routes: this.routes
		})

		router.beforeEach((to, from, next) =>
		{
			document.title =
				this.loc.fromResource(to.meta.resourceKey) + ' | ' +
				this.loc.fromResource('global.applicationName');

			if (to.matched.some(record => record.meta.requiresAuth))
			{
				const token = (store.state.client || {}).token;

				if (token == undefined)
				{
					next({
						path: "/auth",
						query: { redirect: to.fullPath }
					});

					return;
				}

				if ((to.path == '/' || to.meta.clientHasAccess == true) == false)
				{
					next({
						path: "/access-denied"
					});

					return;
				}

				if (to.path != '/hub/person/change-password' && store.state.client.fcp)
				{
					next({
						path: "/hub/person/change-password"
					})

					return;
				}
			}

			next()
		})

		return router
	}
}
