<template>
	<table-cell-errors v-if="hasError" :data="data" />
	<component v-else :is="getComponent" :data="data" />
</template>
<script>
	import TableCellText from './table-cell-text'
	import TableCellHtml from './table-cell-html'
	import TableCellNumber from './table-cell-number'
	import TableCellBoolean from './table-cell-boolean'
	import TableCellColor from './table-cell-color'
	import TableCellPopup from './table-cell-popup'
	import TableCellArray from './table-cell-array'
	import TableCellErrors from './table-cell-errors'
	import TableCellCode from './table-cell-code'

	export default {
		components:
		{
			'table-cell-text': TableCellText,
			'table-cell-html': TableCellHtml,
			'table-cell-number': TableCellNumber,
			'table-cell-boolean': TableCellBoolean,
			'table-cell-color': TableCellColor,
			'table-cell-popup': TableCellPopup,
			'table-cell-array': TableCellArray,
			'table-cell-errors': TableCellErrors,
			'table-cell-code': TableCellCode,
		},

		props: {

			data: Object,
			showError: {
				type: Boolean,
				default: false
			},

			isTrancate: {
				type: Boolean,
				dafault: true
			}
		},

		computed: {

			hasError()
			{
				return this.showError && (this.data.errors || []).length > 0
			},

			getComponent()
			{
				switch (this.data.type)
				{
					case "object":
						return "table-cell-popup";

					case "array":
						return "table-cell-array";

					case "boolean":
						return "table-cell-boolean";
				}

				switch ((this.data.setConfig || {}).inputType) {
					case "color":
						return "table-cell-color";

					case "number":
						if (this.data.name.endsWith("Id") == false)
						{
							return "table-cell-number";
						}
						break;

					case "text-editor":
						return "table-cell-html";
					case "code-editor":
						return "table-cell-code";
				}

				return "table-cell-text";

			}
		},
	}
</script>
