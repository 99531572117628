import Toastify from 'toastify-js'
import Localization from '@/core/localization'
import notifSound from '@/assets/sounds/definite.mp3'

const loc = new Localization()
const addToState = (text, background, duration) =>
{
	if (!window.$kavehToast)
	{
		window.$kavehToast = {};
	}

	const key =
		btoa(unescape(
			encodeURIComponent(`${text}_${background}`))
		)

	if (!window.$kavehToast[key])
	{
		window.$kavehToast[key] = 1

		setTimeout(() =>
		{
			delete window.$kavehToast[key];
		}, duration)

		return true;
	}

	return false
}

const toast =
{
	error(res, msg)
	{
		const duration = 5000;
		const background = "#b02a37";
		const text = msg || loc.fromResource(res);

		if (addToState(text, background, duration))
		{
			Toastify({
				text: text,
				duration: duration,
				newWindow: true,
				close: true,
				gravity: "bottom",
				position: "center",
				stopOnFocus: true,
				style: {
					direction: 'rtl',
					background: background,
				},
			}).showToast();
		}
	},

	success(res, msg)
	{
		const duration = 3000;
		const background = "#146c43";
		const text = msg || loc.fromResource(res);

		if (addToState(text, background, duration))
		{
			Toastify({
				text: text,
				duration: duration,
				newWindow: true,
				close: true,
				gravity: "bottom",
				position: "center",
				stopOnFocus: true,
				style: {
					direction: 'rtl',
					background: background,
				},
			}).showToast();
		}
	},

	warning(res, msg)
	{
		const duration = 5000;
		const background = "#ffe69c";
		const text = msg || loc.fromResource(res);

		if (addToState(text, background, duration))
		{
			Toastify({
				text: text,
				duration: duration,
				newWindow: true,
				close: true,
				gravity: "bottom",
				position: "center",
				stopOnFocus: true,
				style: {
					direction: 'rtl',
					background: background,
					color: "#000"
				},
			}).showToast();
		}
	},


	notification(obj)
	{
		try
		{
			if (window.location.pathname != obj.destination)
			{
				var audio = new Audio(notifSound);
				audio.play();
			}

		} catch (e)
		{
			console.log(e)
		}

		if (addToState(obj.msg, "#f8f8f8", 3000))
		{
			Toastify({
				text: obj.msg,
				duration: 3000,
				newWindow: false,
				destination: obj.destination,
				gravity: "top",
				position: "center",
				stopOnFocus: true,
				style: {
					direction: 'rtl',
					background: "#f8f8f8",
					color: "#000",
					borderRadius: "10px"
				},
			}).showToast();
		}
	}


}

export default toast
